import get from 'lodash/get';
import { external } from '@emobg/web-api-client';
import { createCustomStore } from '@emobg/web-utils';

export const NAMESPACE = 'UserAccount';

export const ACTIONS = {
  fetchUserAccount: 'fetchUserAccount',
  cancelUserAccount: 'cancelUserAccount',
};

export const GETTERS = {
  getUserBadge: 'getUserBadge',
  getUserDrivingLicense: 'getUserDrivingLicense',
  getUserPaymentMethods: 'getUserPaymentMethods',
  getPersonalInformation: 'getPersonalInformation',
  getUserAddressCountry: 'getUserAddressCountry',
  getUserDLIssuedCountry: 'getUserDLIssuedCountry',
  getUserCreationDate: 'getUserCreationDate',
};

export const store = createCustomStore(({ runAsyncFlow }) => ({
  namespaced: true,
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      state[scope].error = value;
    },
  },
  getters: {
    [GETTERS.getUserBadge](state) {
      return get(state, 'data.badge');
    },
    [GETTERS.getUserDrivingLicense](state) {
      return get(state, 'data.drivingLicense');
    },
    [GETTERS.getUserPaymentMethods](state) {
      return get(state, 'data.paymentMethods');
    },
    [GETTERS.getPersonalInformation](state) {
      return get(state, 'data.personalInformation');
    },
    [GETTERS.getUserAddressCountry](_, getters) {
      return get(getters.getPersonalInformation, 'addressCountryCode');
    },
    [GETTERS.getUserDLIssuedCountry](_, getters) {
      return get(getters.getUserDrivingLicense, 'countryCode');
    },
    [GETTERS.getUserCreationDate](_, getters) {
      return get(getters.getPersonalInformation, 'createdAt');
    },
  },

  actions: {
    async [ACTIONS.fetchUserAccount]({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: external.userCompanyUserAccount.getUserAccount,
        params: [userUuid],
      });
    },
    async [ACTIONS.cancelUserAccount]({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: external.userCompany.putCancelUserAccount,
        params: [userUuid],
      });
    },
  },

}));
